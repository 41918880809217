/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const categories = gql`
    {
        categories {
            items {
                uid
                name
                level
                path
                url_path
                url_key
                include_in_menu
                position_in_menu
                custom_url_link
                is_custom_url_link
                children_count
                children {
                    uid
                    name
                    level
                    path
                    url_path
                    url_key
                    include_in_menu
                    position_in_menu
                    custom_url_link
                    is_custom_url_link
                    children_count
                    children {
                        uid
                        name
                        level
                        path
                        url_path
                        url_key
                        include_in_menu
                        position_in_menu
                        custom_url_link
                        is_custom_url_link
                        children_count
                        children {
                            uid
                            name
                            level
                            path
                            url_path
                            url_key
                            include_in_menu
                            position_in_menu
                            custom_url_link
                            is_custom_url_link
                            children_count
                            children {
                                uid
                                name
                                level
                                path
                                url_path
                                url_key
                                include_in_menu
                                position_in_menu
                                custom_url_link
                                is_custom_url_link
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getCmsBlocks = gql`
    query($identifiers: [String]) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                identifier
                title
                content
            }
        }
    }
`;

export const getCustomer = gql`
    {
        customer {
            id
            firstname
            lastname
            email
            is_subscribed
            wishlists {
                items_v2 {
                    items {
                        id
                    }
                }
            }
        }
    }
`;

export const removeToken = gql`
    mutation {
        revokeCustomerToken {
            result
        }
    }
`;

/**
 * scema dynamic resolver url
 * @param url String
 * @returns grapql query
 */

export const getProduct = (key) => {
    const query = gql`{
        products(
            search: "${key}",
            pageSize: 5,
            sort: { relevance: DESC }
          ) {
            items {
                seller{
                    seller_name
                }
                id
                name
                url_key
                small_image {
                    url
                    label
                }
                price_tiers {
                    discount {
                      percent_off
                      amount_off
                    }
                    final_price {
                      currency
                      value
                    }
                    quantity
                }
                price_daily_deal {
                    currency
                    value
                }
                price_range{
                    maximum_price {
                        final_price {
                            currency
                            value
                        }
                        regular_price {
                            currency
                            value
                        }
                    }
                    minimum_price {
                        final_price {
                            currency
                            value
                        }
                        regular_price {
                            currency
                            value
                        }
                    }
                }
            }
            total_count
          }
    }`;
    return query;
};

export const getSeller = gql`
    query getSeller($input: SellerInput) {
        getSeller(input: $input ) {
            id
            name
            logo
            status
            address
            description
            city
            latitude
            longitude
            additional_info
        }
    }
`;

export const getSellerByName = (name) => {
    const query = gql`
        {
            getSeller(input: { keyword: "${name}" }) {
            id
            name
            logo
            status
            address
            description
            city
            latitude
            longitude
            additional_info
            seller_path
            }
        }
    `;
    return query;
};

export const getRecentlyProduct = () => {
    const query = gql`
        query getRecentlyProduct($filter: ProductAttributeFilterInput) {
            products(filter: $filter) {
                items {
                    id
                    name
                    url_key
                    small_image {
                        url
                        label
                    }
                    price_daily_deal {
                        currency
                        value
                    }
                    price_range {
                        maximum_price {
                            discount {
                                amount_off
                                percent_off
                            }
                            final_price {
                                currency
                                value
                            }
                            fixed_product_taxes {
                                amount {
                                    currency
                                    value
                                }
                                label
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                        minimum_price {
                            discount {
                                amount_off
                                percent_off
                            }
                            final_price {
                                currency
                                value
                            }
                            fixed_product_taxes {
                                amount {
                                    currency
                                    value
                                }
                                label
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                    }
                }
            }
        }
    `;
    return query;
};
/**
 * schema dynamic resolver url
 * @param name String
 * @returns graphql query
 */

export const getCategoryByName = (name) => {
    const query = gql`{
        categoryList(filters: { name: { match: "${name}" } }) {
            id
            name
            url_key
            url_path
            __typename
            breadcrumbs {
                category_id
                category_level
                category_name
                category_url_key
                category_url_path
            }
        }
      }`;
    return query;
};

/**
 * [SCHEMA] get currency
 * @param null
 * @return {graphql}
 */
export const getCurrencySchema = gql`
    {
        currency {
            base_currency_code
            base_currency_symbol
            default_display_currency_code
            default_display_currency_symbol
            available_currency_codes
            exchange_rates {
                currency_to
                rate
            }
        }
    }
`;

export const getCountCart = gql`
    query getCartData($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            total_quantity
        }
    }
`;

export const getMagebirdPopup = gql`
    query getMagebirdPopup ($page: String!, $url: String!, $cartId: String!, $categoryId: Int, $sku: String ) {
        getMagebirdPopup(
            page: $page
            url: $url
            cart_id: $cartId
            category_id: $categoryId,
            sku: $sku
        ) {
            data {
                appear_effect
                border_corner_color
                border_corner_radius_in_px
                border_corner_size
                click_selector
                close_automatically_after_x_seconds
                close_on_hover_out
                close_style
                close_when_clicked_outside_popup
                content
                content_type
                cookie_enabled
                cookie_id
                cookie_life_time
                custom_css
                custom_javascript
                devices
                display_frequency
                element_id
                horizontal_position
                horizontal_px_defined_position
                hover_selector
                id
                image
                max_time_per_view
                number_of_visited_page
                overlay_background
                padding_size
                popup_background_color
                popup_box_shadow
                popup_content_width
                popup_corner_style
                returning_or_new_visitor
                scroll_px
                seconds
                show_when
                show_when_code
                stop_further
                title
                url_image_link
                user_ip
                vertical_position
                vertical_px
            }
        }
    }
`;

export const setMagebirdPopupAction = gql`
    mutation setMagebirdPopupAction(
        $popup_id: Int!
        $action: String!
        $time: Float!
    ) {
        setMagebirdPopupAction(
            popup_id: $popup_id, 
            action: $action, 
            time: $time
        ) {
            message
            success
        }
    }
`;

export const getSearchRedirect = gql`
    query getSearchRedirect ($query: String!) {
        getSearchRedirect(query: $query) {
            is_redirect
            query
            redirect_url
        }
    }
`;

export const getRecentlyViewedProduct = gql`
    query getRecentlyViewedProduct ($visitor_id: Int, $page_size: Int) {
        recentViewedProducts(search: "", visitor_id: $visitor_id, pageSize: $page_size, currentPage: 1) {
            currentPage
            items {
                seller {
                    seller_name
                    __typename
                }
                id
                name
                sku
                url_key
                review_count
                amasty_product_labels {
                    label_id
                    label_name
                    label_text
                    image
                    redirect_url
                    image_size
                    position
                    style
                    background_color
                    popup_block
                    __typename
                }
                short_description {
                    html
                    __typename
                }
                price_daily_deal {
                    currency
                    value
                    __typename
                }
                price_range {
                    maximum_price {
                    regular_price {
                        value
                        __typename
                    }
                    final_price {
                        value
                        __typename
                    }
                    discount {
                        amount_off
                        percent_off
                        __typename
                    }
                    __typename
                    }
                    minimum_price {
                    regular_price {
                        value
                        __typename
                    }
                    final_price {
                        value
                        __typename
                    }
                    discount {
                        amount_off
                        percent_off
                        __typename
                    }
                    __typename
                    }
                    __typename
                }
                price_tiers {
                    discount {
                    amount_off
                    percent_off
                    __typename
                    }
                    final_price {
                    currency
                    value
                    __typename
                    }
                    quantity
                    __typename
                }
                small_image {
                    label
                    url
                    __typename
                }
                review {
                    rating_summary
                    reviews_count
                    __typename
                }
                new_from_date
                new_to_date
                only_x_left_in_stock
                stock_status
                weltpixel_labels {
                    productLabel {
                    css
                    customer_group
                    image
                    page_position
                    position
                    priority
                    text
                    text_bg_color
                    text_font_color
                    text_font_size
                    text_padding
                    __typename
                    }
                    categoryLabel {
                    css
                    customer_group
                    image
                    page_position
                    position
                    priority
                    text
                    text_bg_color
                    text_font_color
                    text_font_size
                    text_padding
                    __typename
                    }
                    __typename
                }
                amasty_rewards_guest_highlights {
                    caption_color
                    caption_text
                    visible
                    __typename
                }
                amasty_rewards_highlights {
                    caption_color
                    caption_text
                    visible
                    __typename
                }
                __typename
            }
            pageSize
            totalPages
            total_count
        }
    }
`;

export const generateVisitorId = gql`
    mutation {
        generateVisitorId {
            message
            visitor_id
        }
    }
`;

export default {
    categories,
    getCustomer,
    removeToken,
    getCurrencySchema,
    getProduct,
    getSeller,
    getCategoryByName,
    getSellerByName,
    getRecentlyProduct,
    getCountCart,
    getMagebirdPopup,
    setMagebirdPopupAction,
    getSearchRedirect,
    getRecentlyViewedProduct,
    generateVisitorId,
};
